<template>
  <div class="video-player"></div>
  <div class="video-grid-player">
    <div v-if="isLoading" class="loading-container">
      <div class="loading-spinner"></div>
      <div class="loading-text">加载中...</div>
    </div>
    <div v-else class="video-item" v-for="video in videos" :key="video.name" :class="{'playing': isCurrentVideo(video)}" @click="navigateToVideo(video)">
      <img :src="getThumbnail(video)" alt="Video Thumbnail" class="video-thumbnail" width="200" height="150" />
      <div class="video-info">
        <h3 class="video-name">
          {{ getVideoShowName(video) }}
          <i v-if="isCurrentVideo(video)" class="fas fa-play playing-icon"></i>
        </h3>
        <!-- <p class="video-date">Created: {{ formatDate(video.created) }}</p> -->
      </div>
    </div>
  </div>
  <!-- 添加循环播放控制开关 -->
  <div class="player-controls">
    <div class="play-mode-btn" @click="togglePlayMode">
      <i :class="playModeIcon" class="fas"></i>
    </div>
  </div>
</template>


<script>
window.addEventListener("flutterInAppWebViewPlatformReady", () => {
      console.log("flutterInAppWebViewPlatformReady");
   
      // if (window.flutter_inappwebview) {
      //   console.log("flutter_inappwebview exits");
      //   window.flutter_inappwebview.callHandler('myFlutterMethod', 'Hello from Vue.js!')
      //   .then((result) => {
      //     // 处理来自 Flutter 的返回数据
      //     console.log(result);
      //   }).catch((error) => {
      //     // 处理错误
      //     console.error(error);
      //   })
      // }
      
});
import Artplayer from 'artplayer';
import axios from 'axios';
import { showToast } from 'vant';

export default {
  props: ['video'],
  data() {
    return {
      videoUrl: '', // 视频的URL
      videos: [],
      thumbs: [],
      apiPath: 'api/fs/list',
      currentPath: '/赞美/',
      player: null,
      hasBought: false,
      isLoading: false,
      playMode: 'sequence', // 'sequence' | 'single' | 'random'
    };
  },
  computed: {
    playModeIcon() {
      const icons = {
        sequence: 'fa-sync',              // 列表循环
        single: 'fa-redo',                // 单曲循环
        random: 'fa-random'               // 随机播放
      };
      return icons[this.playMode];
    }
  },
  created() {

  },
  mounted() {
    // 在组件挂载后，解析视频信息并获取视频的URL
    console.log("video-player mounted");
    this.parseVideoInfo();
    // 确保 InAppWebView 已经准备好
    console.log("window:" + window);
    window.addEventListener("flutterInAppWebViewPlatformReady", () => {
      console.log("flutterInAppWebViewPlatformReady");
      this.callFlutterMethod();
    });

    if(this.player != null) {
      this.player.on('video:timeupdate', (time) => {
          console.log('当前播放时间:', this.player.currentTime);
          
      });

    }
    else {
      console.log("player is null");
    }
  },
  methods: {
    callFlutterMethod() {
      if (window.flutter_inappwebview) {
        console.log("flutter_inappwebview exits");
        window.flutter_inappwebview.callHandler('myFlutterMethod', 'Hello from Vue.js!')
        .then((result) => {
          // 处理来自 Flutter 的返回数据
          console.log(result);
        }).catch((error) => {
          // 处理错误
          console.error(error);
        });
      }
      
      
    },
    getThumbnail(video) {
      var videoName = encodeURIComponent(this.getVideoName(video));
      return this.$picPathPrefix + videoName + ".jpg";
    },
    getVideoName(video) {
      return video.name.replace(/\.mp4$/, "");
    },
    getVideoShowName(video) {
      var videoName = this.getVideoName(video);
      return videoName.replace(/^\d+-/, '');
    },
    parseVideoInfo() {
      var video_file_path = decodeURIComponent(this.$route.params.video_file_fath);
      console.log("video file path: " + video_file_path);

      if(video_file_path.endsWith(".mp4")){
        this.videoUrl = this.$videoPathPrefix + encodeURIComponent(video_file_path);
      }
      else{
        this.videoUrl = this.$videoPathPrefix + encodeURIComponent(video_file_path) + '.mp4';
      }
      console.log("video url: " + this.videoUrl);
      this.$store.commit("setVideoUrl", {videoUrl:this.videoUrl});

      this.currentPath = video_file_path.substring(0, video_file_path.lastIndexOf('/'));
      this.getVideoList();
      this.player = new Artplayer({
        container: '.video-player',
        url: this.videoUrl,
        airplay: true,
        playsInline: true,
        autoSize: true,
        lock: true,
        fullscreen: true,
        fullscreenWeb: true,
        pip: true,
        autoplay: true,
        loop: this.playMode === 'single',
        // poster: thumbUrl,
        moreVideoAttr: {
          // @ts-ignore
          "webkit-playsinline": true,
          playsInline: true,
        },
        fastForward: true,
        autoPlayback: true,
        autoOrientation: true,
      });
      if (window.flutter_inappwebview) {
        console.log("flutter_inappwebview exits");
        window.flutter_inappwebview.callHandler('hasBought', 'Hello from Vue.js!')
        .then((result) => {
          // 处理来自 Flutter 的返回数据
          console.log("html+hasBought result:" + result);
          
        }).catch((error) => {
          // 处理错误
          console.error(error);
        })
      }


      // 添加播放结束事件监听
      this.player.on('video:ended', () => {
        console.log("video:ended");
        if (this.playMode === 'single') {
          this.player.play();
        } else {
          this.playNextVideo();
        }
      });

    },
    navigateToVideo(video) {
      var path = this.currentPath + "/" + video.name;
      path = path.replace(/\.mp4$/, ''); // 移除 .mp4 后缀
      
      // 更新路由参数
      this.$router.replace({
        params: { 
          video_file_fath: encodeURIComponent(path)
        }
      });

      // 更新播放器 URL
      var videoUrl = this.$videoPathPrefix + encodeURIComponent(path) + '.mp4';
      console.log("new video url:", videoUrl);
      this.player.url = videoUrl;
      this.$store.commit("setVideoUrl", {videoUrl:videoUrl});

      if (window.flutter_inappwebview) {
        console.log("flutter_inappwebview exits");
        window.flutter_inappwebview.callHandler('hasBought', 'Hello from Vue.js!')
        .then((result) => {
          // 处理来自 Flutter 的返回数据
          console.log(result);
          this.hasBought = result;
        }).catch((error) => {
          // 处理错误
          console.error(error);
        })
      }
    },
    getVideoList() {
      if(this.currentPath == "")
      {
        return;
      }
      this.isLoading = true; // 开始加载

      const requestData = {
        path: this.currentPath,
        password: "",
        page: 1,
        per_page: 0,
        refresh: false
      };
      axios.post(this.$apiHost + this.apiPath, requestData)
        .then(response => {
          var itemList = response.data.data.content;
          var videos = [];
          var thumbs = [];
          //遍历
          for (var i = 0; i < itemList.length; i++) {
            //判断是否为mp4结尾
            if (itemList[i].name.endsWith(".mp4") || itemList[i].is_dir) {
              videos.push(itemList[i]);
            }
            if (itemList[i].name.endsWith(".jpg") || itemList[i].name.endsWith(".webp")) {
              thumbs.push(itemList[i]);
            }
            //判断是否已
          }
          this.videos = videos;
          this.thumbs = thumbs;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false; // 结束加载
        });


    },
    togglePlayMode() {
      const modes = ['sequence', 'single', 'random'];
      const currentIndex = modes.indexOf(this.playMode);
      const nextIndex = (currentIndex + 1) % modes.length;
      this.playMode = modes[nextIndex];
      
      const modeText = {
        sequence: '列表循环',
        single: '单曲循环',
        random: '随机播放'
      };
      
      // 使用 showToast 而不是 Toast
      showToast(modeText[this.playMode]);
      
      // 更新播放器设置
      if (this.player) {
        this.player.loop = this.playMode === 'single';
      }
    },
    playNextVideo() {
      console.log("playNextVideo");
      if (this.videos.length === 0) return;
      
      if (this.playMode === 'random') {
        // 随机播放模式
        const randomIndex = Math.floor(Math.random() * this.videos.length);
        this.navigateToVideo(this.videos[randomIndex]);
        return;
      }
      
      // 列表循环模式
      const currentVideoPath = decodeURIComponent(this.$route.params.video_file_fath);
      const currentIndex = this.videos.findIndex(v => {
        const videoPath = this.currentPath + "/" + v.name;
        const normalizedVideoPath = videoPath.replace(/\.mp4$/, '');
        return normalizedVideoPath === currentVideoPath;
      });
      
      if (currentIndex !== -1 && currentIndex < this.videos.length - 1) {
        this.navigateToVideo(this.videos[currentIndex + 1]);
      } else if (currentIndex !== -1) {
        this.navigateToVideo(this.videos[0]);
      }
    },
    isCurrentVideo(video) {
      const currentVideoPath = decodeURIComponent(this.$route.params.video_file_fath);
      const videoPath = this.currentPath + "/" + video.name;
      const normalizedVideoPath = videoPath.replace(/\.mp4$/, '');
      return normalizedVideoPath === currentVideoPath;
    },
  },
};
</script>

<style>
.video-player {
  /* 设置容器宽度为100% */
  /* width: 100%; */
  width: 100%;
  height: 250px;
  position: fixed;
  top: 0px;
  /* You can adjust the top, right, bottom, or left values to position it where you want */
  left: 0;
  /* 可根据需要设置容器高度 */
  /* height: 0; */
  /* padding-bottom: 计算得到的百分比值(例如16:9的视频是56.25%) */
  /* 这将根据视频的宽高比自动调整容器高度 */
  /* padding-bottom: 56.25%;
  position: relative;
  overflow: hidden; */
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-container video {
  /* 设置视频元素填充父级容器 */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.play-button i {
  font-size: 50px;
  color: #fff;
}

.video-grid-player {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 将网格分为两列，每列平均占据剩余空间 */
  gap: 20px;
  /* margin-top: 60vw; */
  padding-top: 280px;
  padding-bottom: 20px;
  /* 设置列间隔，根据需要进行调整 */
}

.video-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-thumbnail {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.video-info {
  margin-top: 0px;
  text-align: center;
}

.video-name {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.video-date,
.video-size {
  font-size: 14px;
  margin-top: 5px;
}

.loading-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.loading-text {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  margin: 0 auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 添加开关控制样式 */
.player-controls {
  position: fixed;
  top: 250px;
  right: 10px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.play-mode-btn {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-mode-btn i {
  font-size: 20px;
  color: white;
  transition: transform 0.3s; 
}

.play-mode-btn:hover i {
  transform: scale(1.2);          
}

/* 确保引入了 Vant 的样式 */
@import 'vant/lib/index.css';

.video-item.playing {
  background: rgba(52, 152, 219, 0.1);
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(52, 116, 219, 0.3);
}

.playing-icon {
  font-size: 12px;
  color: #3498db;
  margin-left: 5px;
}
</style>